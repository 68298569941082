import React, { useRef, useEffect } from "react";
import "./App.css";

import fond from "./assets/photo/fond2.mp4";
import Rooter from "./components/Router";
import { ElfsightWidget } from "react-elfsight-widget";

export default function App() {
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.playbackRate = 0.65; // Définir la vitesse de lecture à 0.5
		}
	}, []);

	return (
		<div id="main">
			<ElfsightWidget
				widgetId="a5142191-17bd-4a6e-a41f-e3e7bdb0f3a0"
				lazy
				modern
				className="rating"
			/>
			<div className="bg">
				<video
					ref={videoRef}
					src={fond}
					autoPlay
					loop
					muted
					alt="Des plantes bougent avec le vent"
					type="video/mp4"
				/>
				<Rooter />
			</div>
		</div>
	);
}
